import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/posts.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When we want to instrument our application, we don't want to worry about which monitoring system we want to use, now or in the future.
Nor do we want to change a lot of code throughout our microservice because we need to change from system X to system Y.`}</p>
    <h2>{`Meet Micrometer!`}</h2>
    <p>{`So what is Micrometer you ask?`}</p>
    <p>{`Basically, it comes down to this:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Think SLF4J, but for metrics.`}</p>
    </blockquote>
    <p>{`A simple facade over the instrumentation clients for the most popular monitoring systems is being provided by `}<a href="https://micrometer.io" target="_blank">{`Micrometer`}</a>{`.
It allows you to instrument your code with `}<strong parentName="p">{`dimensional metrics with a vendor-neutral interface`}</strong>{` and decide on the monitoring system as a last step.
Using this interface, we can support multiple monitoring systems and switch easily to an other system with little to no hassle.
It already contains built-in support for `}<a href="https://prometheus.io" target="_blank"><strong parentName="p">{`Prometheus`}</strong></a>{`, Netflix `}<a href="https://github.com/Netflix/atlas" target="_blank"><strong parentName="p">{`Atlas`}</strong></a>{`, and `}<a href="https://www.datadoghq.com" target="_blank"><strong parentName="p">{`Datadog`}</strong></a>{`, while `}<a href="https://www.influxdata.com" target="_blank"><strong parentName="p">{`InfluxDB`}</strong></a>{`, `}<a href="https://github.com/etsy/statsd" target="_blank"><strong parentName="p">{`statsd`}</strong></a>{`, and `}<a href="https://graphiteapp.org" target="_blank"><strong parentName="p">{`Graphite`}</strong></a>{` are on their way!`}</p>
    <h2>{`Using Micrometer in your application`}</h2>
    <p>{`Starting with Spring Boot 2, more specifically since milestone `}<a href="https://spring.io/blog/2017/09/15/spring-boot-2-0-0-m4-available-now" target="_blank">{`M4`}</a>{`, Micrometer becomes the defacto instrumentation library that will be powering the delivery of application metrics from Spring.
Luckily for us, they also backported this functionality to Spring Boot 1.x through an additional library dependency!
Just add the `}<inlineCode parentName="p">{`micrometer-spring-legacy`}</inlineCode>{` module together with the additional monitoring system module, and you're good to go!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-gradle"
      }}>{`compile 'io.micrometer:micrometer-spring-legacy:latest.release'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-maven"
      }}>{`<dependency>
  <groupId>io.micrometer</groupId>
  <artifactId>micrometer-spring-legacy</artifactId>
  <version>\${micrometer.version}</version>
</dependency>
`}</code></pre>
    <h2>{`Creating metrics`}</h2>
    <p>{`There are a couple of ways to create meters.
We will cover all different types, when to use them, and furthermore how to implement them.`}</p>
    <h3>{`Dimensions/Tags`}</h3>
    <p>{`A meter is uniquely identified by its name and dimensions (also called tags).
Dimensions are a way of adding dimensions to metrics, so they can be sliced, diced, aggregated and compared.
For example, we have a meter named `}<inlineCode parentName="p">{`http.requests`}</inlineCode>{` with a tag `}<inlineCode parentName="p">{`uri`}</inlineCode>{`.
With this meter we could see the overall amount of HTTP requests, but also have the option to drill down and see the amount of HTTP requests for a specific URI.`}</p>
    <h3>{`Counters`}</h3>
    <p>{`Counters are a cumulative metric that represents a single numerical value that only ever goes up.
They are typically used to count requests served, tasks completed, errors occurred, etc.
Counters should `}<strong parentName="p">{`not`}</strong>{` be used to expose current counts of items whose number can also go down, gauges are a better fit for this use case.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "612px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8d0d5684bd8bdee1d12bb939c0dd7432/8c76f/counter.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.84523809523809%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAABa0lEQVQ4y81TTUsCURT1v6TlzKiT9gEGCRlCFpoRUbQoiqCgoFW0ipbRKkr6EfkT3LSPliNhjI5zk+hfnO6dN2PTxtRatDjc97jnnncu977Ik2ngZdJAo4cEGikNVm4OVrEAq7AAK59TUbBU8HINU1dcrrEYTcYja0VE7D2dwBujG0QWpPw8aHUFVF4GlYqgSknd18qgxRy6ptarIcYH45m1IvKCJFyOPcg9FYdrjMNNCCbUOUAyrjg+v+Obkm49wa6flJeESIFoJsnRRyaEEEdq+gqOgh8Fv7mcSvV1N5jDoIAnSfExkBEDaRz12FcuxB/MIU/azc6gdXkBZ28H7dMTtM7P2LHJYvpwDqU14sl2eP+a9Trs6yvY1TvYtzdwZ9Oe8+EF9Sg6vHOvtQfY91U425t8rsFZr3jte5yhWhYX2Wm0j4/gbG3A2d9F+/BACY08FPkxMhR2S1pUnUceSni5f702f73Y/0rwExgP1gUudsgmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8d0d5684bd8bdee1d12bb939c0dd7432/a6cd2/counter.webp 336w", "/static/8d0d5684bd8bdee1d12bb939c0dd7432/d1d8c/counter.webp 612w"],
              "sizes": "(max-width: 612px) 100vw, 612px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8d0d5684bd8bdee1d12bb939c0dd7432/d99f2/counter.png 336w", "/static/8d0d5684bd8bdee1d12bb939c0dd7432/8c76f/counter.png 612w"],
              "sizes": "(max-width: 612px) 100vw, 612px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8d0d5684bd8bdee1d12bb939c0dd7432/8c76f/counter.png",
              "alt": "Counter showing how many errors have occurred",
              "title": "Counter showing how many errors have occurred",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MeterRegistry registry = ...
Counter counter = registry.counter("received.messages");
    
counter.increment();
`}</code></pre>
    <h3>{`Gauges`}</h3>
    <p>{`A gauge is a metric that represents a single numerical value that can arbitrarily go up and down.
Gauges are typically used for measured values like current memory usage, but also "counts" that can go up and down, like the number of messages in a queue.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "928px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/cc488/gauge.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.86904761904761%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABrElEQVQoz3WSPUvbURTG/Q7V/30759y3/F/SJqlKtAZNMA0qhVZtsUXt0EJxEALapSQUAnbuN1AcRAQHly6ln6BTt04upeDo4jfoTZoaDRbOcrnP7zz3PPeMGKKbRQBZmiw0GvXZWn2uXq3UajPVJ4tLlZlH4WpIPKIRbxYhONA+ztPD9EE1K8ynrpzkigVjkwAPiW/BRqOVKO5jY01+3uVf2tG3VnTY4nub7Nm4EBLNf2FCzVFO0sf3cLHHfn1gX9/xszf8R5NddqLzNttpSKXuhAmtwiih1i5cdaKDDTGZAlPIJBJh87E8eavWZv3ylJJq4N+HDaFgOPeCfn9ix69FJJBLtJpsQAHHOCAZ4dJXE37CooI+34cd4D1PnSZctNh8CcY4eo1KSJAy3FrqGeR8JclWnGUAdgBTN6fRPB215fdtFmLrxg6qVCyWCoX+XCFqa8tZtuodvwPO6LQjf+5w24VJCR5+e3N9PfYelerKrJ3OZ8978K1nh7RGY2pvqf2XIg5te1M5Y3LOWa3/alDrchw/dXbgPNgYTcaRkBRshzbpupzWcej17/gHecFuXeBHH1MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/a6cd2/gauge.webp 336w", "/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/0fa99/gauge.webp 672w", "/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/5bce2/gauge.webp 928w"],
              "sizes": "(max-width: 928px) 100vw, 928px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/d99f2/gauge.png 336w", "/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/30d16/gauge.png 672w", "/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/cc488/gauge.png 928w"],
              "sizes": "(max-width: 928px) 100vw, 928px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6c8c6ab0ccb47ebc24f6fea5b7ad1984/cc488/gauge.png",
              "alt": "Gauge showing how many messages still need to be processed",
              "title": "Gauge showing how many messages still need to be processed",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MeterRegistry registry = ...

AtomicInteger currentHttpRequests = registry.gauge("current.http.requests", new AtomicInteger(0));
Queue<Message> receivedMessages = registry.gauge("unprocessed.messages", new ConcurrentLinkedQueue<>(), ConcurrentLinkedQueue::size);
`}</code></pre>
    <p>{`Instead of returning a gauge, the `}<inlineCode parentName="p">{`gauge`}</inlineCode>{` method will rather return the thing that is being observed.
This allows us to have quick one liners that both create the object to be observed and set up metrics around it.`}</p>
    <h3>{`Timers`}</h3>
    <p>{`Timers measure both the rate that a particular piece of code is called and the distribution of its duration.
They do not record the duration until the task is complete.
These are useful for measuring short-duration latencies and the frequency of such events.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1344px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d0c9298d4c097379e9f6d9b5c758a1f4/d2782/timer.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.89285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsSAAALEgHS3X78AAABCUlEQVQY00WM7UuDUBjF/R+CyPS+6K5tejW5rZoRjpG0qTPEtwxaoxoUfdlaL6tPfSmI+qt7hCI4PBzO7zlHMhjTCKEY/4sQpKiGznbFTlcIm3PShL+I/j0ThCTLNDVEKMIwAdI1ijeR8Ox+2mtzpqzL6oaiYUJVBJQoqBF4SliLSja3OpYBZapiomJ5TXb3rclbOvuukpsAtrqHTkvXOhaDy9225WwRhHuBGOSexLlZ3IfVYyQObACDzDt7GV9/VdP37PIjr1+Tq89ydNHP5qPhuV8sQ8irpxjQcOJL7raT3h3Xq/jkNhjPjupVAlv5IsznYbEIy2WUN9MxmPIhAgR9MKfPsZ/s/QAdN0LlmVCtfQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d0c9298d4c097379e9f6d9b5c758a1f4/a6cd2/timer.webp 336w", "/static/d0c9298d4c097379e9f6d9b5c758a1f4/0fa99/timer.webp 672w", "/static/d0c9298d4c097379e9f6d9b5c758a1f4/3dddb/timer.webp 1344w", "/static/d0c9298d4c097379e9f6d9b5c758a1f4/2911a/timer.webp 1864w"],
              "sizes": "(max-width: 1344px) 100vw, 1344px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d0c9298d4c097379e9f6d9b5c758a1f4/d99f2/timer.png 336w", "/static/d0c9298d4c097379e9f6d9b5c758a1f4/30d16/timer.png 672w", "/static/d0c9298d4c097379e9f6d9b5c758a1f4/47218/timer.png 1344w", "/static/d0c9298d4c097379e9f6d9b5c758a1f4/d2782/timer.png 1864w"],
              "sizes": "(max-width: 1344px) 100vw, 1344px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d0c9298d4c097379e9f6d9b5c758a1f4/47218/timer.png",
              "alt": "Timer showing how long it takes to process messages",
              "title": "Timer showing how long it takes to process messages",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`long startTime = System.nanoTime();

MeterRegistry registry = ...

Timer timer = registry.timer("timer");
    
// this will record how long it took us to get a registry and create a new timer
timer.record(System.nanoTime() - startTime, TimeUnit.NANOSECONDS);
`}</code></pre>
    <p>{`Or we could just annotate a method with `}<inlineCode parentName="p">{`@Timed`}</inlineCode>{` and let Micrometer do the rest for us`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Timed
public void doSomethingWhichShouldBeFastButIsActuallyReallySlow() {

}
`}</code></pre>
    <h3>{`Long task timers`}</h3>
    <p>{`The long task timer is a special type of timer that lets you measure time while an event being measured is `}<strong parentName="p">{`still running`}</strong>{`.
To time a long running task we use the same `}<inlineCode parentName="p">{`@Timed`}</inlineCode>{` annotation, but we set the property `}<inlineCode parentName="p">{`longTask`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Timed(longTask = true)
@Scheduled
public void doSomethingWhichCanTakeALoooooongTime() {

}
`}</code></pre>
    <p>{`It is up to the application framework to make something happen with `}<inlineCode parentName="p">{`@Timed`}</inlineCode>{`.
In case it isn't able to do that, you can still use the long task timer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MeterRegistry registry = ...

LongTaskTimer looooongTimer = registry.more().longTaskTimer("sync");

private void doSomethingWhichCanTakeALoooooongTime() {
    looooongTimer.record(() => {
        // actually do some synchronization which takes a loooooong time
    });
}
`}</code></pre>
    <h3>{`Distribution summaries`}</h3>
    <p>{`A distribution summary is used to track the distribution of events.
It is similar to a timer but more general in that the size does not have to be a period of time.
Usually it is used to sample observations of things like response sizes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MeterRegistry registry = ...

DistributionSummary summary = registry.summary("response.size");
`}</code></pre>
    <h2>{`Summary statistics`}</h2>
    <p>{`Micrometer provides quantile statistics computed at `}<strong parentName="p">{`instrumentation time`}</strong>{` and histograms for use in calculating quantile statistics at `}<strong parentName="p">{`query time`}</strong>{` for monitoring systems that support this.`}</p>
    <h3>{`Quantiles`}</h3>
    <p>{`Quantiles are cutpoints dividing the range of a probability distribution into contiguous intervals with equal probabilities, or dividing the observations in a sample in the same way.
Timers and distribution summaries can be enriched with quantiles computed in your app prior to shipping to a monitoring backend.
Depending on the size of your deployments, computing quantiles at instrumentation time may or may not be useful.
It is `}<strong parentName="p">{`not possible`}</strong>{` to aggregate quantiles across a cluster.`}</p>
    <p>{`Four quantile algorithms are provided out of the box with different tradeoffs:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`WindowSketchQuantiles`}</inlineCode>{` - The importance of an observation is decayed as it ages.
This is the most computationally costly algorithm.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Frugal2UQuantiles`}</inlineCode>{` - Successive approximation algorithm that converges towards the true quantile with enough observations.
This is the least costly algorithm, but exhibits a higher error ratio in early observations.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CKMSQuantiles`}</inlineCode>{` - Lets you trade computational complexity for error ratio on a per-quantile basis.
Often, it is desirable for higher quantiles to have a lower error ratio (e.g. 0.99 at 1% error vs. 0.5 at 5% error).
This algorithm is still more computationally expensive than Frugal.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`GKQuantiles`}</inlineCode>{` - Lets you trade computational complexity for error ratio across all quantiles.
This is used inside of WindowSketchQuantiles.`}</li>
    </ul>
    <h3>{`Histograms`}</h3>
    <p>{`A histogram measures the statistical distribution of values in a stream of data.
It samples observations, like HTTP request durations or database transaction durations, and counts them in buckets.
They can be used to compute quantiles or other summary statistics like min, max, average or median.
Because histograms buckets are exposed as individual counters to the monitoring backend, it is possible to aggregate observations across a distributed system and compute summary statistics like quantiles for an entire cluster.
Naturally, the error rate of the computed summary statistic will be higher because of the lossy nature of putting data in buckets.`}</p>
    <h2>{`Binders`}</h2>
    <p>{`Binders define a collection of meters and are used to encapsulate best practices for monitoring certain types of objects or a part of the application's environment.
For example, the `}<inlineCode parentName="p">{`JvmThreadMetrics`}</inlineCode>{` binder which gauges thread peak, number of daemon threads, and live threads.`}</p>
    <p>{`Micrometer ships with a basic set of binders:`}</p>
    <ul>
      <li parentName="ul">{`JVM and system monitoring`}</li>
      <li parentName="ul">{`Cache monitoring`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Executor`}</inlineCode>{` and `}<inlineCode parentName="li">{`ExecutorService`}</inlineCode>{` monitoring`}</li>
      <li parentName="ul">{`Logback monitoring`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      